(function () {
	var telAttr = 'data-tel',
		agenceAttr = 'data-destinatel',
		agenceDefaut = 'web';

	// Apparition du numéro de téléphone dans les boutons au click
	function btnTel(el) {
		var tel = el.getAttribute(telAttr);
		el.firstElementChild.innerHTML = tel;
		el.removeAttribute(telAttr);
		var agence = el.getAttribute(agenceAttr) ? el.getAttribute(agenceAttr) : agenceDefaut;
		ga('send', 'event', 'clic-contact', 'clic-telephone', 'clic-telephone-' + agence);
	}

	// Event click multifonction
	document.addEventListener('click', function (e) {
		// console.log(typeof ga);
		if (typeof ga !== 'undefined') {
			// Btn tel
			if (e.target.closest("[" + telAttr + "]")) {
				var elTel = e.target.closest("[" + telAttr + "]");
				if (elTel.getAttribute(telAttr) !== '') {
					btnTel(elTel);
					e.preventDefault();
				}
			}
			if (e.target.closest(".estimer-start-home")) {
				ga('send', 'event', 'estimation', 'clic-pré-estimer', 'Home-page');
				// console.log('clic-pré-estimer');
			}

			if (e.target.closest(".estimer-start-estimer")) {
				ga('send', 'event', 'estimation', 'clic-estimer-mon-bien-en-2-minutes', 'Estimer');
				// console.log('clic-estimer-mon-bien-en-2-minutes');
			}

			if (e.target.closest(".estimer-start-vendu")) {
				ga('send', 'event', 'estimation', 'clic-estimer-nos-dernieres-transactions', 'Archive-vendu');
				// console.log('clic-estimer-nos-dernieres-transactions');
			}

			if (e.target.closest(".estimer-start-vendre")) {
				ga('send', 'event', 'estimation', 'clic-estimer-mon-bien-en-2-minutes', 'Page-Vendre');
				// console.log('clic-estimer-nos-dernieres-transactions');
			}

			if (e.target.closest(".register_newsletter")) {
				ga('send', 'event', 'clic-newsletter', 'clic-newsletter', 'clic-newsletter-ok');
			}

			if (e.target.closest(".achat-neuf")) {
				ga('send', 'event', 'estimation', 'clic-bien-a-vendre', 'Espace-Neuf');
			}
		}
	});

	document.onreadystatechange = function () {
		if (typeof ga !== 'undefined') {
			if (document.readyState === 'complete') {
					ga('set', 'dimension1', document.body.classList.contains('logged-in') ? 'Connecte' : 'NonConnecte');
					ga('require', 'displayfeatures');

				var url = new URL(window.location);

				if (-1 !== url.search.indexOf('alertsaved')) {
					if (-1 !== url.pathname.indexOf('location')) {
						ga('send', 'event', 'clic-creation-alerte', 'clic-creation-alerte', 'clic-creation-alerte-location');
					} else if (-1 !== url.pathname.indexOf('achat')) {
						ga('send', 'event', 'clic-creation-alerte', 'clic-creation-alerte', 'clic-creation-alerte-achat');
					}
				}

				var dataForm = url.searchParams.get('form');
				var dataSpec = url.searchParams.get('spec');
				if (null !== dataForm && null !== dataSpec) {
					ga('send', 'event', 'clic-formulaire-ok', 'clic-formulaire-' + dataForm, 'clic-formulaire-' + dataSpec);
				}

				document.addEventListener('click', function (e) {
					var item = e.target.closest('[type="submit"]');
					if (!item) return;
					var form_cont = item.closest('p'),
						form_mail = form_cont ? form_cont.querySelector('input[type="email"]') : false;
					if (form_mail && form_mail.value !== '' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form_mail.value)) {
						ga('send', 'event', 'clic-newsletter', 'clic-newsletter', 'clic-newsletter-ok');
					}
				});
			}
		}
	}
}());